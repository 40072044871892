<template>
  <div style="display: flex; justify-content: center; height: 100%; align-items: center">
    <div class="common-add-dialog">
      <div style="text-align: center; font-weight: 600">
        {{reportDetail.title}}
      </div>
      <div style="display: flex; justify-content: space-between">
        <div>
          <span style="margin-right: 10px">项目名称</span>
          <span>{{reportDetail.project_title}}</span>
        </div>
        <div>
          <span style="margin-right: 10px">项目编号</span>
          <span>{{reportDetail.project_sn}}</span>
        </div>
      </div>
      <div style="font-weight: 600">
        总体进度
      </div>
      <div>
        <el-progress :text-inside="true" :stroke-width="26" :percentage="reportDetail.percent"></el-progress>
      </div>
      <div style="font-weight: 600">
        里程碑信息
      </div>
      <div>
        <common-table
            :tableData="milestoneTableData"
            :tableProp="milestoneTableProp"
        />
      </div>
      <div style="font-weight: 600">
        下周计划
      </div>
      <div>
        <common-table
            :tableData="planTableData"
            :tableProp="planTableProp"
            @pageCurrentChange="pageCurrentChange"
            @handleSizeChange="handleSizeChange"
            @openDetail="openDetail"
        />
      </div>
      <div style="font-weight: 600">
        资源需求
      </div>
      <div style="color: red">
        当前所属里程碑：{{reportDetail.details.zi_yuan_xu_qiu?reportDetail.details.zi_yuan_xu_qiu[0]:''}}
      </div>
      <div>
        {{ reportDetail.details.zi_yuan_xu_qiu?reportDetail.details.zi_yuan_xu_qiu[1]:'' }}
      </div>
      <div>
        补充资源需求：{{ reportDetail.details.zi_yuan_xu_qiu?reportDetail.details.zi_yuan_xu_qiu[2]:'' }}
      </div>
    </div>
  </div>
</template>

<script>
import {progressReportList} from "@/api/project_management/my_project";

export default {
  components: {},
  props: [],
  data() {
    return {
      //  里程碑信息表格配置
      milestoneTableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: false, //是否分页
        statusName: 'statusName',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          type: 2,
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
          method2: 'handleSizeChange',
        },
        table: [{
          title: '里程碑名称',
          field: 'title',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '完成时间',
          field: 'finish_time',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        }, {
          title: '完成度(%)',
          field: 'percent',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '任务状态',
          field: 'status',
          isWidth: false,
          width: '100px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }],
      },
      milestoneTableData: [],
      //  下周计划表格配置
      planTableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: false, //是否分页
        statusName: 'statusName',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          type: 2,
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
          method2: 'handleSizeChange',
        },
        table: [{
          title: '任务名称',
          field: 'title',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '计划开始时间',
          field: 'plan_start_time',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '计划结束时间',
          field: 'plan_end_time',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '任务状态',
          field: 'status',
          isWidth: false,
          width: '100px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }],
      },
      planTableData: [],
      //  报告详情
      reportDetail: {
        title: '',
        project_title: '',
        project_sn: '',
        percent: '',
        details: {},
      },
    }
  },
  created() {
    this.openDetail()
  },
  mounted() {
  },
  methods: {
    //详情点击
    openDetail(){
      this.reportDetail = this.$options.data().reportDetail

      progressReportList({rid: this.$route.query.id}).then((res)=>{
        this.reportDetail.title = res.data.title
        this.reportDetail.project_title = res.data.project_title
        this.reportDetail.project_sn = res.data.project_sn
        this.reportDetail.percent = res.data.percent*100
        this.reportDetail.details = JSON.parse(res.data.details)
        for (let i = 0; i < this.reportDetail.details.li_cheng_bei_xin_xi.length; i++) {
          this.reportDetail.details.li_cheng_bei_xin_xi[i].percent = this.reportDetail.details.li_cheng_bei_xin_xi[i].percent*100
        }
        this.milestoneTableData = this.reportDetail.details.li_cheng_bei_xin_xi
        for (let i = 0; i < this.reportDetail.details.xia_zhou_ji_hua.length; i++) {
          switch (this.reportDetail.details.xia_zhou_ji_hua[i].status) {
            case 0:
              this.reportDetail.details.xia_zhou_ji_hua[i].status = '未开始';
              break;
            case 1:
              this.reportDetail.details.xia_zhou_ji_hua[i].status = '进行中';
              break;
            case 2:
              this.reportDetail.details.xia_zhou_ji_hua[i].status = '结束';
              break;
          }
        }
        this.planTableData = this.reportDetail.details.xia_zhou_ji_hua
        console.log(this.reportDetail)
        this.reportDetail.isDialog = true

      })
    },
  },
}
</script>

<style lang="scss" scoped>
.common-add-dialog{
  width: 700px;
  padding: 50px 50px 200px 50px;
  background-color: white;
  div{
    margin-top: 20px;
  }
}
</style>
<style>
body{
  background-color: #f2f2f2;
}
</style>